import { Box, styled } from '@mui/material';

export const Wrapper = styled(Box)(({ theme }) => ({
    width: 'fit-content',
    display: 'flex',
    margin: '0 auto',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '8px',
    cursor: 'default',
    padding: '0 30px',
}));

export const CounterBox = styled(Box)(({ theme }) => ({
    width: 'fit-content',
    padding: '6px 14px',
    background:
        theme.palette.mode === 'light'
            ? 'linear-gradient(104deg, #023E97 16.31%, #05BAD1 100%)'
            : 'linear-gradient(90deg, rgba(255, 255, 255, 0.20) -25.07%, rgba(255, 255, 255, 0.00) 51.22%, rgba(255, 255, 255, 0.20) 111.34%)',
    backdropFilter: ' blur(146.5px)',
}));
