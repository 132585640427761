import React from 'react';
import { Box, styled } from '@mui/material';

const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    borderRadius: '0px',
    boxShadow:
        '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    background: theme.palette.backgrounds.bg3,
}));

interface SimpleCardInterface {
    [key: string]: any;
}

const SimpleCard: React.FC<React.PropsWithChildren<SimpleCardInterface>> = ({
    children,
    ...rest
}) => {
    return <StyledBox {...rest}>{children}</StyledBox>;
};
export default SimpleCard;
