import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { getRandomNumber } from '@/lib/helpers/helpers';

const Wrapper = styled(Box)({
    display: 'flex',
    width: '100vw',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
});

const InitialLoading: React.FC<{ sx?: any; id?: string }> = ({ sx = {}, id = '' }) => {
    const wrapSx = sx ? { ...sx } : {};

    return (
        <Wrapper sx={wrapSx} id={id || getRandomNumber(0, 10000).toString()}>
            <CircularProgress
                color="primary"
                size={88}
                sx={{
                    '&.MuiCircularProgress-root': {
                        color: (theme) =>
                            theme.palette.mode === 'dark'
                                ? theme.palette.text.primary
                                : theme.palette.primary.main,
                    },
                }}
            />
        </Wrapper>
    );
};

export default InitialLoading;
