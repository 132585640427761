import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const MessageWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',
    minWidth: '400px',
    backgroundColor: theme.palette.backgrounds.bg3,
    borderRadius: '8px',
    gap: '6px',
    boxShadow: `0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)`,
}));

export const MessageContent = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
});
export const MessageIcon = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: '10%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
});
export const MessageClose = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    width: 'unset',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
});
