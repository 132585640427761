import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { LicenseInfo } from '@mui/x-license';
//import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store, { persistor } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';

const container = document.getElementById('root');
// @ts-ignore
const root = createRoot(container);

LicenseInfo.setLicenseKey(import.meta.env.REACT_APP_MUI || '');

root.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>,
);

// Preventing logs on the prod
function noop() {}
if (import.meta.env.REACT_APP_ENVR !== 'dev') {
    console.log = noop;
    console.info = noop;
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
