import { Box, Stack, styled } from '@mui/material';
import _ConnectingIcon from '@/assets/images/icons/connection_icon.svg?react';
import { Button } from '@/components/Common/Buttons';

export const ComponentWrapper = styled(Stack)({
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    gap: '12px',
    padding: '48px 24px',
});
export const ExpiresWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    fontSize: '10px',
});

export const ConnectingIcon = styled(_ConnectingIcon)({
    width: '40px',
    minWidth: '40px',
    height: '40px',
});

export const CardWrapper = styled(Box)(({ theme }) => ({
    margin: '44px 0 80px',
    background:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(90deg, #26262C 0%, rgba(46, 46, 53, 1.00) 76.26%, rgba(69, 69, 79, 1.00) 100%)'
            : 'linear-gradient(104deg, #023E97 16.31%, #05BAD1 100%)',
    display: 'flex',
    width: '100%',
    maxWidth: '548px',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
    borderRadius: '16px',
}));
export const ContentContainer = styled(Box)({
    display: 'flex',
    width: '100%',
    gap: '24px',
    alignItems: 'center',
});
export const Content = styled(Box)({
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
});
export const ExchangesListWrapper = styled(Box)({
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    flexWrap: 'wrap',
});
export const List = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
});

export const ConnectNowButton = styled(Button)({
    fontWeight: '700',
    width: 'calc(100% - 24px)',
    maxWidth: '400px',
    padding: '10px 16px',
});
