import { Box, styled } from '@mui/material';
import top_banner_bg from '@/assets/images/rewards-top-banner-bg.jpg';

export const RewardsPageWrapper = styled(Box)({
    maxWidth: '1280px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '24px',
    padding: '18px 24px',
    margin: '0 auto',
});
export const TopBanner = styled(Box)({
    position: 'relative',
    padding: '28px 0 30px',
    backgroundImage: `url(${top_banner_bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
    textAlign: 'center',
});

export const ListWrapper = styled(Box)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    width: '100%',
    gap: '24px',
    [theme.breakpoints.down(1100)]: {
        gridTemplateColumns: 'repeat(1, 1fr)',
    },
}));
