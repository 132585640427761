import React, { ComponentType } from 'react';

type ComponentPromise<T = any> = Promise<{ default: ComponentType<React.PropsWithChildren<T>> }>;
const clearCacheData = () => {
    caches.keys().then((names) => {
        names.forEach(async (name) => {
            await caches.delete(name).then();
        });
    });
};
const retry = (componentImport, name): ComponentPromise => {
    return new Promise((resolve, reject) => {
        // check if the window has already been refreshed
        const hasRefreshed = JSON.parse(
            window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false',
        );
        // try to import the component

        componentImport()
            .then((component) => {
                window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false');
                resolve(component);
            })
            .catch((error) => {
                if (!hasRefreshed) {
                    // not been refreshed yet
                    clearCacheData(); //clear browser cache data
                    window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true');
                    return window.location.reload(); // refresh the page
                }
                reject(error); // Default error behaviour as already tried refresh
            });
    });
};

const lazyRetry = function (
    componentImport: () => ComponentPromise,
    name: string,
): React.LazyExoticComponent<React.ComponentType<React.PropsWithChildren<any>>> {
    return React.lazy(() => retry(componentImport, name));
};

export default lazyRetry;
