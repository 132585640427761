import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { selectAccountType } from '@/state/member/memberSelectors';
import { secondsToDhms, shortDurationString } from '@/lib/helpers/helpers';
import { formatDuration } from 'date-fns';

const useTrialExpires = () => {
    const [timer, setUpdateTimer] = useState(new Date());
    useEffect(() => {
        const timerId = setInterval(() => {
            setUpdateTimer(new Date());
        }, 1000 * 6);

        return () => clearInterval(timerId);
    }, []);

    const accountType = useSelector((state: RootState) => selectAccountType(state));
    const expires = useMemo(() => {
        const durationS = (new Date(accountType.expiredAt).getTime() - timer.getTime()) / 1000;
        return shortDurationString(
            formatDuration(secondsToDhms(durationS), { format: ['days', 'hours', 'minutes'] }),
        );
    }, [accountType, timer]);
    return { expires };
};

export default useTrialExpires;
