import React from 'react';
import MUIButton from '@mui/material/Button';
import { styled } from '@mui/material';
import { ButtonProps } from '@mui/material/Button/Button';

const StyledButton = styled(MUIButton)(({ theme }) => ({
    borderRadius: '6px',
    textTransform: 'none',
    padding: '9px 26px ',
    '&:hover': {
        borderRadius: '6px',
    },
}));

const Button: React.FC<
    React.PropsWithChildren<
        ButtonProps & { target?: '_blank' | '_self' | '_parent' | '_top' | 'framename' }
    >
> = ({ children, ...rest }) => {
    return (
        <StyledButton disableElevation {...rest}>
            {children}
        </StyledButton>
    );
};

export default Button;
