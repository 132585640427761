import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';

const Input: React.FC<React.PropsWithChildren<TextFieldProps>> = (props) => {
    return (
        <TextField
            id={props.id}
            label={props.label}
            variant="outlined"
            {...props}
            sx={{
                margin: '16px 0',
                '& label.Mui-focused': {
                },
                '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light' ? theme.palette.divider : 'unset',
                    '& fieldset': {
                        borderColor: (theme) => theme.palette.backgrounds.bg4,
                        borderRadius: '8px',
                        '&:hover': {
                            borderColor: (theme) => theme.palette.backgrounds.bg4,
                        },
                    },
                    '&:hover fieldset': {
                        borderColor: (theme) => theme.palette.secondary.main,
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: (theme) => theme.palette.primary.main,
                        backgroundColor: 'rgba(55, 125, 255, 0.15)',
                    },
                    '&.Mui-error fieldset': {
                        backgroundColor: 'rgba(255, 86, 48, 0.1)',
                    },
                    '&.Mui-disabled fieldset': {
                        borderColor: (theme) => theme.palette.backgrounds.bg4,
                    },
                },
                ...props.sx,
            }}
            error={props.error}
        />
    );
};

export default Input;
