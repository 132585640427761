import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
import Fetch from 'i18next-fetch-backend';

const getTranslationsHost = () => {
    let host = 'https://translations.coinmarketman.com';
    if (import.meta.env.MODE === 'development') {
        host = 'http://localhost:3009';
    } else if (import.meta.env.REACT_APP_ENVR === 'dev') {
        host = 'https://dev-translations.coinmarketman.com';
    } else if (import.meta.env.REACT_APP_ENVR === 'stg') {
        host = 'https://stg-translations.coinmarketman.com';
    }
    return `${host}/translations/cmm-dashboard/%{lng}/%{ns}.json`;
};

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    .use(Fetch)

    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        backend: {
            loadPath: getTranslationsHost(),
        },
        fallbackLng: 'en',
        // debug: import.meta.env.REACT_APP_ENVR === 'dev',
        interpolation: {
            prefix: '%{',
            suffix: '}',
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;
