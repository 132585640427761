import { Box, IconButton, ListItem, ListItemButton, styled } from '@mui/material';
import _EmptyIcon from '@/assets/images/icons/journal_empty_icon.svg?react';
import _NotesIcon from '@/assets/images/icons/journal_notes_icon.svg?react';
import { MAX_WIDTH } from '@/lib/constants';
import Input from '@/components/Common/Input';

export const Wrapper = styled(Box)({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexGrow: 1,
    gap: '24px',
});

export const RowBox = styled(Box)({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '50px',
});

export const PageHeaderWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    height: '100%',
    padding: '0 30px',
    maxWidth: MAX_WIDTH,

    [theme.breakpoints.down('md')]: {
        padding: '0 16px',
    },
}));

export const TitleRow = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '24px 0',
});

export const EmptyJournalWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    flexGrow: 1,
    marginTop: '15%',
});

export const EmptyJournalDataContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '500px',
});

export const EmptyIcon = styled(_EmptyIcon)({});
export const NotesIcon = styled(_NotesIcon)({
    width: '23px',
    height: '23px',
});

export const JournalListWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    flexGrow: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    minHeight: 'calc(100vh - 350px)',
});

export const JournalItem = styled(ListItem)(({ theme }) => ({
    background: theme.palette.backgrounds.bg3,
    borderRadius: '8px',
    marginBottom: '16px',
    padding: `0 !important`,
    boxShadow:
        '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
}));

export const JournalButton = styled(ListItemButton)(({ theme }) => ({
    minHeight: '80px',
    borderRadius: '8px',
    width: '100%',
    [theme.breakpoints.down('md')]: {
        padding: 0,
    },
}));

export const ContentWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
    },
}));

export const JournalItemTitle = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '20%',
    [theme.breakpoints.down('md')]: {
        padding: '16px',
        width: '100%',
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
}));

export const JournalItemStats = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '80%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('md')]: {
        display: 'grid',
        padding: '16px',
        width: '100%',
        gap: '10px',
        gridTemplateColumns: '1fr 1fr',
    },
}));

export const TextBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
        // color: theme.palette.text.primary,
        // transition: 'color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        transition: `none !important`,
    },
    [theme.breakpoints.down('md')]: {
        alignItems: 'flex-start',
    },
}));

export const NoteFormWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '24px',
    backgroundColor: theme.palette.backgrounds.bg3,
    borderRadius: '10px',
}));

export const Form = styled('form')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    gap: '16px',
});

export const InputBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
});

export const CustomInput = styled(Input)(({ theme }) => ({
    width: '100%',
    background: theme.palette.backgrounds.bg4,
    borderRadius: '8px',
    marginBottom: 0,
    marginTop: '8px',
}));

export const ButtonWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
});

export const ButtonsRow = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '24px',
});

export const NoteItemWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
});

export const ImageWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    margin: '24px 0',
});

export const Image = styled('img')({
    width: '100%',
    height: 'auto',
});

export const TitleWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
});

export const ActionButtonWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    width: '10%',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '12px',
});

export const EditButtonWrapper = styled(ActionButtonWrapper)(({ theme }) => ({
    width: '100%',
    [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-end',
    },
}));

export const ConfirmButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: `${theme.palette.success.main}1A`,
    width: '24px',
    height: '24px',
}));
export const CancelButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: `${theme.palette.error.main}1A`,
    width: '26px',
    height: '26px',
}));

export const RiskBox = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px 12px',
    borderRadius: '27px',
});
