import React, { useEffect } from 'react';

import { useRegisterSW } from 'virtual:pwa-register/react';
import { MessageClose, MessageContent, MessageIcon, MessageWrapper } from './ReloadPromtStyled';
import UpdateIcon from '@mui/icons-material/Update';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import { useTranslation } from 'react-i18next';

function ReloadPrompt() {
    // replaced dyanmicaly
    const reloadSW = '__RELOAD_SW__';

    const {
        needRefresh: [needRefresh],
        updateServiceWorker,
    } = useRegisterSW({
        onRegisteredSW(swUrl, r) {
            console.log(`AVI Service Worker at: ${swUrl}`);
            // @ts-expect-error just ignore
            if (reloadSW === 'true') {
                r &&
                    setInterval(() => {
                        console.log('Checking for sw update');
                        r.update();
                    }, 20000 /* 20s for testing purposes */);
            } else {
                // eslint-disable-next-line prefer-template
                console.log('SW Registered: ' + r);
            }
        },
        onRegisterError(error) {
            console.log('SW registration error', error);
        },
    });

    useEffect(() => {
        console.log('Need Refresh', needRefresh);
    }, [needRefresh]);

    const { t } = useTranslation('translation', { keyPrefix: 'general_header' });

    return (
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={needRefresh}>
            <MessageWrapper>
                <MessageIcon>
                    <UpdateIcon
                        sx={{ color: (theme) => theme.palette.success.main, fontSize: '30px' }}
                    />
                </MessageIcon>
                <MessageContent>
                    <Typography variant={'p14'} fontWeight={700}>
                        {t('sw_refresh_title')}
                    </Typography>
                    <Typography variant={'p12'} color={'customText.text1'}>
                        {t('sw_refresh_subtitle')}
                    </Typography>
                </MessageContent>
                <MessageClose>
                    <Button
                        variant={'text'}
                        color={'primary'}
                        onClick={() => updateServiceWorker()}
                    >
                        {t('sw_refresh_button')}
                    </Button>
                </MessageClose>
            </MessageWrapper>
        </Snackbar>
    );
}

export default ReloadPrompt;
