import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import SimpleCard from '@/components/Cards/SimpleCard';

export const BoundaryPageWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
});

export const CardWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40%',
    maxWidth: '500px',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: '0 8px',
    },
}));

export const TextWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: '12px',
});

export const CustomCard = styled(SimpleCard)({
    padding: '24px 16px',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
});

export const LinkWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '0 10px',
    marginTop: '12px',
    '& p': {
        lineHeight: '30px',
    },
    gap: '6px',
});
