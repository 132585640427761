import React, { ReactNode } from 'react';
import {
    BodyContainer,
    CardContent,
    ItemWrapper,
    RewardsDiscordIcon,
    RewardsXIcon,
} from '@/pages/Rewards/components/RewardsListItem/RewardsListItemStyled';
import { Box, Typography } from '@mui/material';
import { Reward, RewardName } from '@/state/rewards/rewardsTypes';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Common/Buttons';
import { ConnectingIcon } from '@/components/FirstTimer/TrialPeriodFirstTimer/TrialPeriodFirstTimerStyled';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/lib/constants';
import { useReward } from '@/state/rewards/rewardsSlice';
import { useDispatch } from 'react-redux';
import rewardsApi from '@/api/rewardsApi';

interface RewardsListItemProps {
    reward: Reward;
}

const defineButtonLabel = (rewardName: Reward['name']): string => {
    switch (rewardName) {
        case RewardName.DISCORD_JOIN:
            return 'discord_join_label';
        case RewardName.X_FOLLOW:
            return 'x_follow_label';
        case RewardName.EXCHANGE_CONNECT:
            return 'exchange_connect_label';
        default:
            return 'Just Do it';
    }
};
const defineCardIcon = (rewardName: Reward['name']): ReactNode | null => {
    switch (rewardName) {
        case RewardName.EXCHANGE_CONNECT:
            return (
                <ConnectingIcon
                    sx={{
                        width: '44px',
                        height: '44px',
                        path: { fill: (theme) => theme.palette.secondary.main },
                    }}
                />
            );
        case RewardName.X_FOLLOW:
            return <RewardsXIcon />;
        case RewardName.DISCORD_JOIN:
            return <RewardsDiscordIcon />;
        default:
            return null;
    }
};

const RewardsListItem: React.FC<RewardsListItemProps> = ({ reward }) => {
    const { t } = useTranslation('rewards-hub');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const defineAction = (rewardName: Reward['name']): (() => void) => {
        switch (rewardName) {
            case RewardName.X_FOLLOW:
                return async () => {
                    window.open('https://x.com/intent/follow?screen_name=CoinMarketMan', '_blank');
                    await rewardsApi.useXFollow();
                    dispatch(useReward(rewardName));
                };
            case RewardName.DISCORD_JOIN:
                return async () => {
                    window.open('https://discord.com/invite/zu65pmBGfV', '_blank');
                    await rewardsApi.useDiscordJoin();
                    dispatch(useReward(rewardName));
                };
            case RewardName.EXCHANGE_CONNECT:
                return () => {
                    navigate(ROUTES.API_MANAGER_ADD);
                };
            default:
                return () => {};
        }
    };
    return (
        <ItemWrapper>
            <Typography variant="p16" fontWeight={600}>
                {reward.description}
            </Typography>
            <BodyContainer>
                {defineCardIcon(reward.name)}
                <CardContent>
                    <Box>
                        <Typography
                            lineHeight="24px"
                            variant="p16"
                            component="p"
                            fontWeight={700}
                            color="text.primary"
                        >
                            {reward.title}
                        </Typography>
                        <Typography
                            lineHeight="17px"
                            minHeight="17px"
                            component="p"
                            variant="p12"
                            fontWeight={500}
                            color="text.primary"
                        >
                            {reward.available
                                ? ''
                                : t('reward_available_label', { isUnlocked: t('unlocked_label') })}
                        </Typography>
                    </Box>
                    <Button
                        onClick={defineAction(reward.name)}
                        disabled={!reward.available}
                        sx={{ padding: '10px 16px', minWidth: '114px', height: '42px' }}
                        variant="contained"
                        size="small"
                    >
                        {t(defineButtonLabel(reward.name))}
                    </Button>
                </CardContent>
            </BodyContainer>
        </ItemWrapper>
    );
};

export default RewardsListItem;
