import React from 'react';
import {
    CounterBox,
    Wrapper,
} from '@/pages/Rewards/components/FreeTrialCounter/FreeTrialCounterStyled';
import { Typography } from '@mui/material';
import useTrialExpires from '@/hooks/useTrialExpires';
import { useTranslation } from 'react-i18next';

const FreeTrialCounter = () => {
    const { expires } = useTrialExpires();
    const { t } = useTranslation('rewards-hub');
    return (
        <Wrapper>
            <Typography variant="p20" fontWeight="700">
                {t('current_trial_label')}
            </Typography>
            <CounterBox>
                <Typography color="common.white" variant="p16" fontWeight="700">
                    {expires}
                </Typography>
            </CounterBox>
        </Wrapper>
    );
};

export default FreeTrialCounter;
