import React from 'react';
import {
    BannerWrapper,
    ContentBox,
    DiscountBannerIcon,
    TextBox,
} from '@/pages/Rewards/components/DiscountBanner/DiscountBannerStyled';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Common/Buttons';
import { ROUTES } from '@/lib/constants';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { selectDiscountData } from '@/state/payments/paymentsSelectors';

const DiscountBanner = () => {
    const discountData = useSelector((state: RootState) => selectDiscountData(state));
    const { t } = useTranslation(['rewards-hub', 'buttons']);

    return (
        <BannerWrapper>
            <ContentBox>
                <DiscountBannerIcon />
                <TextBox>
                    <Typography component="p" variant="p18" fontWeight={500} color="common.white">
                        {t('discount_banner_title')}
                    </Typography>
                    {discountData.couponCode === 'FREE_TRIAL_DISCOUNT' ? (
                        <Typography variant="p14" fontWeight={500} color="common.white">
                            {t('discount_banner_text')}
                        </Typography>
                    ) : (
                        <Typography variant="p14" fontWeight={500} color="common.white">
                            {t('discount_banner_partners_text', {
                                discountPercent: discountData?.discountSize,
                            })}{' '}
                        </Typography>
                    )}
                </TextBox>
            </ContentBox>
            <Button
                sx={{
                    width: {
                        xs: '100%',
                        sm: 'unset',
                    },
                }}
                href={ROUTES.UPGRADE}
                color="secondary"
                variant="contained"
            >
                <Typography whiteSpace="nowrap" variant="p14" fontWeight={700}>
                    {t('buttons:buy_now_label')}
                </Typography>
            </Button>
        </BannerWrapper>
    );
};

export default DiscountBanner;
