import { Box, Stack, styled } from '@mui/material';
import _DiscountBannerIcon from '@/assets/images/icons/discount-banner-icon.svg?react';

export const BannerWrapper = styled(Stack)(({ theme }) => ({
    flexDirection: 'row',
    padding: '10px 16px',
    borderRadius: '16px',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '12px',
    background:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.12) 100%), linear-gradient(104deg, #023E97 16.31%, #05BAD1 100%)',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));

export const DiscountBannerIcon = styled(_DiscountBannerIcon)(({ theme }) => ({
    width: '40px',
    minWidth: '40px',
    height: '72px',
}));
export const TextBox = styled(Box)(({ theme }) => ({}));
export const ContentBox = styled(Stack)(({ theme }) => ({
    gap: '29px',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start',
    },
}));
