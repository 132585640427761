import React from 'react';
import { Box, styled } from '@mui/material';
import LogoImg from '../../assets/images/cmm_logo_white.svg?react';

const LogoImage = styled(LogoImg)({
    objectFit: 'contain',
    width: '100%',
    height: 'auto',
});

const Logo: React.FC<React.PropsWithChildren<{ onClick: () => void; sx?: any; boxSx?: any }>> = ({
    onClick,
    sx = {},
    boxSx = {},
}) => {
    return (
        <Box
            onClick={onClick}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                cursor: 'pointer',
                maxWidth: { md: '140px', lg: '140px', sm: '125px', xs: '125px' },
                ...boxSx,
            }}
        >
            <LogoImage sx={sx} />
        </Box>
    );
};

export default Logo;
