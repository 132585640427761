import { Box, Stack, styled } from '@mui/material';
import _RewardsXIcon from '@/assets/images/icons/rewards-x-icon.svg?react';
import _RewardsDiscordIcon from '@/assets/images/icons/rewards-discord-icon.svg?react';

export const ItemWrapper = styled(Box)(({ theme }) => {
    const isLightMode = theme.palette.mode === 'light';
    return {
        backgroundColor: theme.palette.backgrounds.bg3,
        padding: '20px 16px',
        width: '100%',
        ...(isLightMode
            ? {
                  backgroundColor: theme.palette.backgrounds.bg6,
                  borderRadius: '4px',
                  border: `1px solid #C1DBFF`,
              }
            : {}),
    };
});

export const RewardsXIcon = styled(_RewardsXIcon)({
    width: '44px',
    height: '44px',
});
export const RewardsDiscordIcon = styled(_RewardsDiscordIcon)({
    width: '44px',
    height: '44px',
});
export const BodyContainer = styled(Box)(({ theme }) => {
    const isLightMode = theme.palette.mode === 'light';
    return {
        marginTop: '20px',
        backgroundColor: theme.palette.backgrounds.bg4,
        width: '100%',
        borderRadius: '6px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '24px 16px',
        gap: '12px',
        [theme.breakpoints.down(420)]: {
            alignItems: 'flex-start',
        },
        ...(isLightMode
            ? {
                  border: `1px solid #C1DBFF`,
              }
            : {}),
    };
});
export const CardContent = styled(Stack)(({ theme }) => ({
    flex: '1',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '12px',
    flexDirection: 'row',
    [theme.breakpoints.down(420)]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
}));
