import React from 'react';
import { supportEmail } from '@/lib/constants/generalConstants';
import { logoutMember } from '@/state/member/useMember';
import {
    BoundaryPageWrapper,
    CardWrapper,
    CustomCard,
    LinkWrapper,
    TextWrapper,
} from '@/components/ErrorBoundary/ErrorBoundaryStyled';
import ReportIcon from '@mui/icons-material/Report';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import SendIcon from '@mui/icons-material/Send';
import ListItemText from '@mui/material/ListItemText';
import CachedIcon from '@mui/icons-material/Cached';
import PasswordIcon from '@mui/icons-material/Password';
import LogoutIcon from '@mui/icons-material/Logout';

const CrashSite = () => {
    const onSupportClick = () => {
        window.open(`mailto:${supportEmail}?subject=CMM dashboard crashed`);
    };

    const onReloadClick = () => {
        window.location.reload();
    };

    const onErrorLogSend = () => {
        console.log('sent');
    };

    const onForceLogout = async () => {
        try {
            await logoutMember(false);
            onReloadClick();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <BoundaryPageWrapper>
            <CardWrapper>
                <CustomCard>
                    <ReportIcon color="error" sx={{ fontSize: '88px' }} />
                    <TextWrapper>
                        <Typography
                            component="h1"
                            variant="p22"
                            fontWeight={700}
                            mb="16px"
                            textAlign="center"
                            alignSelf="center"
                        >
                            Omg! The CMM site just crashed...
                        </Typography>
                        <Typography variant="p14" component="p" mb="12px">
                            We definitely did something wrong and it will be fixed in a short time
                        </Typography>
                    </TextWrapper>
                    <LinkWrapper>
                        <List
                            sx={{ width: '100%', bgcolor: 'backgrounds.bg4' }}
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            subheader={
                                <ListSubheader component="div" id="nested-list-subheader">
                                    Meanwhile, you can choose what would you do next:
                                </ListSubheader>
                            }
                        >
                            <ListItemButton onClick={onSupportClick}>
                                <ListItemIcon>
                                    <SendIcon />
                                </ListItemIcon>
                                <ListItemText primary="Contact customer support via email" />
                            </ListItemButton>
                            <ListItemButton onClick={onReloadClick}>
                                <ListItemIcon>
                                    <CachedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Reload the website. It helps sometime" />
                            </ListItemButton>
                            <ListItemButton onClick={onErrorLogSend}>
                                <ListItemIcon>
                                    <PasswordIcon />
                                </ListItemIcon>
                                <ListItemText primary="Send us the error log and wait for a fix" />
                            </ListItemButton>
                            <ListItemButton onClick={onForceLogout}>
                                <ListItemIcon>
                                    <LogoutIcon />
                                </ListItemIcon>
                                <ListItemText primary="Force log out and reload the website" />
                            </ListItemButton>
                        </List>
                        <Typography variant="p14" component="p" mt="20px">
                            Your browser settings do not allow for 3rd party cookies and access to
                            the browser local storage. Please change your browser settings (or
                            contact support for help) to make the site work properly.
                        </Typography>
                    </LinkWrapper>
                </CustomCard>
            </CardWrapper>
        </BoundaryPageWrapper>
    );
};
export default CrashSite;
