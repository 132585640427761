import React from 'react';
import { ListWrapper, RewardsPageWrapper, TopBanner } from '@/pages/Rewards/RewardsStyled';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import Logo from '@/components/Logo';
import rewardsApi from '@/api/rewardsApi';
import RewardsListItem from '@/pages/Rewards/components/RewardsListItem';
import DiscountBanner from '@/pages/Rewards/components/DiscountBanner';
import FreeTrialCounter from '@/pages/Rewards/components/FreeTrialCounter';
import { useDispatch, useSelector } from 'react-redux';
import { selectRewardsList } from '@/state/rewards/rewardsSelectors';
import { setRewardsList } from '@/state/rewards/rewardsSlice';
import { RootState } from '@/store/store';
import { selectAccountType } from '@/state/member/memberSelectors';
import { PLANS } from '@/state/payments/paymentsTypes';

const Rewards = () => {
    const { accountType } = useSelector((state: RootState) => selectAccountType(state));
    const { t } = useTranslation('rewards-hub');
    const dispatch = useDispatch();
    const rewardsList = useSelector((state: RootState) => selectRewardsList(state));

    const getRewards = async () => {
        try {
            const { data } = await rewardsApi.getRewards();
            dispatch(setRewardsList(data));
        } catch (error) {
            console.log(error);
        }
    };

    React.useEffect(() => {
        getRewards();
    }, []);
    return (
        <RewardsPageWrapper>
            <TopBanner>
                <Logo
                    sx={{
                        position: 'absolute',
                        top: '11px',
                        left: '11px',
                        width: '62px',
                        height: 'auto',
                    }}
                    onClick={() => {}}
                />
                <Typography color="common.white" fontWeight={700} sx={{ fontSize: '24px' }}>
                    {t('top_banner_title')}
                </Typography>
                <Typography color="common.white" fontWeight={600} variant="p16">
                    {t('top_banner_subtitle')}
                </Typography>
            </TopBanner>
            {accountType === PLANS.TRIAL && <FreeTrialCounter />}
            <ListWrapper>
                {rewardsList.map((reward) => (
                    <RewardsListItem key={reward.name} reward={reward} />
                ))}
            </ListWrapper>
            {accountType === PLANS.TRIAL && <DiscountBanner />}
        </RewardsPageWrapper>
    );
};

export default Rewards;
