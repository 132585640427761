import axiosInstance, { API_ROUTES } from '../lib/http/httpRequest';
import { AxiosResponse } from 'axios';
import { Reward } from '@/state/rewards/rewardsTypes';

const getRewards = (): Promise<AxiosResponse<Reward[]>> => axiosInstance.get(API_ROUTES.REWARD_HUB);
const useXFollow = (): Promise<AxiosResponse<string>> => axiosInstance.post(API_ROUTES.X_FOLLOW);
const useDiscordJoin = (): Promise<AxiosResponse<string>> =>
    axiosInstance.post(API_ROUTES.DISCORD_JOIN);
export default {
    getRewards,
    useXFollow,
    useDiscordJoin,
};
