import React from 'react';
import { ThemeOptions } from '@mui/material/styles';
import { createTheme } from '@mui/material';
// https://bareynol.github.io/mui-theme-creator/

declare module '@mui/material/styles' {
    interface Backgrounds {
        bg1: string;
        bg2: string;
        bg3: string;
        bg4: string;
        bg5: string;
        bg6: string;
        sideBarBackground: string;
        pageHeaderBackground: string;
        layoutHeaderBg: string;
        progressDefaultBg: string;
        loginFormBg: string;
        loginInputsBg: string;
    }

    interface CustomTextColor {
        text1: string;
        text2: string;
        text3: string;
        text4: string;
        text5: string;
        drawerTextColor: string;
    }

    interface Palette {
        backgrounds: Backgrounds;
        customText: CustomTextColor;
    }

    // allow configuration using `createTheme`
    interface PaletteOptions {
        backgrounds: Backgrounds;
        customText: CustomTextColor;
    }

    interface TypographyVariants {
        p20: React.CSSProperties;
        p18: React.CSSProperties;
        p16: React.CSSProperties;
        p14: React.CSSProperties;
        p12: React.CSSProperties;
        p10: React.CSSProperties;
        p22: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        p20: React.CSSProperties;
        p18: React.CSSProperties;
        p16: React.CSSProperties;
        p14: React.CSSProperties;
        p12: React.CSSProperties;
        p10: React.CSSProperties;
        p22: React.CSSProperties;
    }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        p20: true;
        p18: true;
        p16: true;
        p14: true;
        p12: true;
        p10: true;
        p22: true;
    }
}

export const lightThemeOptions: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#247FFF',
            light: '#579DFF',
            dark: '#0B4EAC',
        },
        secondary: {
            main: '#FFAB00',
            dark: '#db8a11',
        },
        background: {
            default: '#FAFBFF',
        },
        text: {
            primary: '#43485C',
            secondary: '#727687',
            // hint: '#B0B7C3',
        },
        error: {
            main: '#FD6E4E',
        },
        warning: {
            main: '#FFAB00',
        },
        info: {
            main: '#43485C',
        },
        success: {
            main: '#38CB89',
        },
        divider: '#EDF0F7',
        backgrounds: {
            bg1: '#ffffff',
            bg2: '#EDF0F7',
            bg3: '#ffffff',
            bg4: '#ffffff',
            bg5: '#262731',
            bg6: '#F5F7FB',
            sideBarBackground: '#0B4EAC',
            layoutHeaderBg: '#FFFFFF',
            pageHeaderBackground: '#ffffff',
            progressDefaultBg: '#E7E8EB',
            loginFormBg: '#26272C',
            loginInputsBg: '#3B3C46',
        },
        customText: {
            text1: '#707077',
            text2: '#43485C',
            text3: '#B0B7C3',
            text4: '#FFF',
            text5: '#1a1b23',
            drawerTextColor: '#FFFFFF',
        },
    },
    typography: {
        fontFamily: ['Manrope', 'Serif'].join(','),
        p20: {
            fontSize: '20px',
            lineHeight: '30px',
            fontWeight: '500',
        },
        p18: {
            fontSize: '18px',
            lineHeight: '27px',
            fontWeight: '500',
        },
        p16: {
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: '500',
        },
        p14: {
            fontSize: '14px',
            lineHeight: '21px',
            fontWeight: '500',
        },
        p12: {
            fontSize: '12px',
            lineHeight: '17px',
            fontWeight: '500',
        },
        p10: {
            fontSize: '10px',
            lineHeight: '15px',
            fontWeight: '500',
        },
        p22: {
            fontSize: '22px',
            lineHeight: '33px',
            fontWeight: '500',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    components: {
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    textTransform: 'unset',
                    fontWeight: '500',
                    borderRadius: '6px',
                    '&:hover': {
                        borderRadius: '6px',
                    },
                },
            },
        },
    },
};

export const darkThemeOptions: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#247FFF',
            light: '#579DFF',
            dark: '#0B4EAC',
        },
        secondary: {
            main: '#FFAB00',
            dark: '#db8a11',
        },
        background: {
            default: '#1A1B23',
        },
        text: {
            primary: '#fff',
            secondary: '#727687',
            // hint: '#B0B7C3',
        },
        error: {
            main: '#FD6E4E',
            light: '#ff7575',
        },
        warning: {
            main: '#FFAB00',
        },
        info: {
            main: '#FFF',
        },
        success: {
            main: '#38CB89',
            light: '#77d0aa',
        },
        divider: '#3B3C46',
        backgrounds: {
            bg1: '#1A1B23',
            bg2: '#1D1E25',
            bg3: '#26272C',
            bg4: '#3B3C46',
            bg5: '#262731',
            bg6: '#F5F7FB',
            sideBarBackground: '#0E0E0E',
            layoutHeaderBg: '#1A1B23',
            pageHeaderBackground: '#1A1B23',
            progressDefaultBg: '#3B3C46',
            loginFormBg: '#26272C',
            loginInputsBg: '#3B3C46',
        },
        customText: {
            text1: '#707077',
            text2: '#F0F0F0',
            text3: '#B0B7C3',
            text4: '#FFF',
            text5: '#1a1b23',
            drawerTextColor: '#777E90',
        },
    },
    typography: {
        fontFamily: ['Manrope', 'Serif'].join(','),
        p20: {
            fontSize: '20px',
            lineHeight: '30px',
            fontWeight: '500',
        },
        p18: {
            fontSize: '18px',
            lineHeight: '27px',
            fontWeight: '500',
        },
        p16: {
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: '500',
        },
        p14: {
            fontSize: '14px',
            lineHeight: '21px',
            fontWeight: '500',
        },
        p12: {
            fontSize: '12px',
            lineHeight: '17px',
            fontWeight: '500',
        },
        p10: {
            fontSize: '10px',
            lineHeight: '15px',
            fontWeight: '500',
        },
        p22: {
            fontSize: '22px',
            lineHeight: '33px',
            fontWeight: '500',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    components: {
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    textTransform: 'unset',
                    fontWeight: '500',
                    borderRadius: '6px',
                    '&:hover': {
                        borderRadius: '6px',
                    },
                },
            },
        },
    },
};

const defaultLight = createTheme();
export const lightTheme = createTheme({ ...defaultLight, ...lightThemeOptions });
export const darkTheme = createTheme({ ...defaultLight, ...darkThemeOptions });
